import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
// VueSweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
// axios
import VueAxios from 'vue-axios';
import Axios from 'axios';
// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    iconfont: 'mdiSvg',
   },
   theme: {
    defaultTheme: 'light',
    //
  },
});
// Crypto
import VueCryptojs from 'vue-cryptojs';
// Notification
import Notifications from '@kyvg/vue3-notification';
import { VDateInput } from 'vuetify/labs/VDateInput'

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App);
app.use(store);
app.use(vuetify);
app.use(VueCryptojs);
app.use(VueSweetalert2);
app.use(VDateInput);
app.use(Notifications);
app.use( VueAxios, Axios);
app.use(router);
app.mount('#app');
